<template>
    <div class="o-layout o-layout--large">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center">
                    <title-text :title="PROVIDER.Order.OrderDashboard.Title" />
                    <recess-button
                        :title="PROVIDER.Shared.Popups.DashboardInfoPopup.Title"
                        icon="info"
                        variant="variant4"
                        class="d-flex qa-sara-was-here qa-order-dashboard-info-button"
                        @click.native.prevent="openDashboardInfoModal()"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <recess-card variant="variant1">
                        <recess-button
                            :title="PROVIDER.Order.OrderDashboard.AllOrdersButton"
                            variant="secondary"
                            class="qa-orders-list-button"
                            :url="{ name: 'order-overview' }"
                        />
                    </recess-card>
                </div>
            </div>

            <div class="row my-5">
                <div class="col-12">
                    <div class="c-dashboard">
                        <div class="c-dashboard-item">
                            <h1 class="c-dashboard-title qa-total-orders-count">
                                {{ totalOrders }}
                            </h1>
                            <h6>{{ PROVIDER.Order.OrderDashboard.TotalOrders }}</h6>
                        </div>
                        <div class="c-dashboard-item">
                            <h1 class="c-dashboard-title u-color-warning qa-total-pending-provider-orders">
                                {{ totalPendingProviderOrders }}
                            </h1>

                            <h6>{{ PROVIDER.Order.OrderDashboard.TotalOrdersInPendingProvider }}</h6>
                        </div>
                        <div class="c-dashboard-item">
                            <h1 class="c-dashboard-title u-color-success qa-total-invoiced-orders">
                                {{ totalInvoicedOrders }}
                            </h1>

                            <h6>{{ PROVIDER.Order.OrderDashboard.TotalOrdersInvoiced }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <dashboard-info-pop-up-component
            v-show="isDashboardInfoModalVisible"
            :is-modal-visible="isDashboardInfoModalVisible"
            dashboard-type="order"
            @close="closeDashboardInfoModal"
        />
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import orderDashboardMixin from '@/mixins/orderDashboardMixin'

const DashboardInfoPopUpComponent = () =>
    import('@/components/molecules/DashboardInfoPopUpComponent')

export default {
    name: 'OrderDashboard',
    components: {
        DashboardInfoPopUpComponent
    },
    mixins: [orderDashboardMixin],
    data() {
        return {
            PROVIDER,
            isDashboardInfoModalVisible: false
        }
    },
    methods: {
        openDashboardInfoModal() {
            this.isDashboardInfoModalVisible = true
        },
        closeDashboardInfoModal() {
            this.isDashboardInfoModalVisible = false
        }
    }
}
</script>
